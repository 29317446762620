import Api from './Api.js';
const organization_id = () => {
    return window.localStorage.getItem("OrganizationID");
};

export default {
    getMasterSummary(payload) {
        return Api().post(`v2/report/masterSummary?organization_id=${organization_id()}`, payload)
    },

    getMasterSummaryChilds(payload) {
        return Api().post(`v2/report/masterSummaryChilds?organization_id=${organization_id()}`, payload)
    },

    getVendorSummary(payload) {
        return Api().post(`v2/report/vendorSummary?organization_id=${organization_id()}`, payload)
    },

    getVendorSummaryUnitEconomics(payload) {
        return Api().post(`v2/report/vendorSummaryUnitEconomics?organization_id=${organization_id()}`, payload)
    },

    getAllParentCategory() {
        return Api().get(`v2/report/parentCategory?organization_id=${organization_id()}`)
    },

    getSubCategoryByParentName(payload) {
        return Api().post(`v2/report/getSubCategoryByParentName?organization_id=${organization_id()}`, payload)
    },

    getInvoiceSummaryReport(payload) {
        return Api().post(`v2/report/invoiceSummary?organization_id=${organization_id()}`, payload)
    }
}
