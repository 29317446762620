<template>
  <div>
    <MasterSummaryReport class="mb-5" />
    <VendorSummaryReport class="mb-5" />
    <!-- <VendorUnitEconomicsSummaryReport /> -->
  </div>
</template>

<script>
import MasterSummaryReport from "./MasterSummaryReport.vue";
import VendorSummaryReport from "./VendorSummaryReport.vue";
import VendorUnitEconomicsSummaryReport from "./VendorUnitEconomicsSummaryReport.vue";
export default {
  components: {
    MasterSummaryReport,
    VendorSummaryReport,
    VendorUnitEconomicsSummaryReport,
  },
};
</script>
