<template>
  <vx-card>
    <div class="flex flex-wrap justify-between items-center form-title">
      <h4>Master Summary Report</h4>
    </div>
    <!-- Filters -->
    <div class="row">
      <div class="col-sm-4">
        <h6>Category</h6>
        <multiselect
          label="category_name"
          trackBy="category_name"
          class="w-full"
          v-model="form.category"
          :options="categoryList"
          :searchable="true"
          open-direction="bottom"
          placeholder="Select Category"
          deselect-label="Remove"
          autocomplete="off"
          multiple
        />
      </div>
      <div class="col-sm-4">
        <h6>Project</h6>
        <multiselect
          label="project_name"
          trackBy="project_id"
          class="w-full"
          v-model="form.project"
          :options="projectList"
          :searchable="true"
          open-direction="bottom"
          placeholder="Select Project"
          deselect-label="Remove"
          autocomplete="off"
          multiple
        />
      </div>
      <div class="col-sm-4">
        <h6>&nbsp;</h6>
        <div class="row">
          <vs-button
            color="primary"
            type="filled"
            icon="search"
            class="col-12 col-sm-5 mr-0 sm:mr-2 mb-2 sm:mb-0"
            @click="searchData"
          >
            Search
          </vs-button>
          <vs-button
            color="warning"
            type="border"
            class="col-12 col-sm-5"
            @click="resetData"
          >
            Reset
          </vs-button>
        </div>
      </div>
    </div>

    <div
      class="flex items-center justify-end mt-5"
      v-if="reportData.length > 0"
    >
      <vs-button
        color="primary"
        type="filled"
        icon="file_download"
        class="w-full sm:w-1/4 md:w-1/6 lg:w-1/12"
        @click="exportReportExcel"
      >
        Export
      </vs-button>
    </div>

    <!-- <div class="mt-5" v-if="reportData.length > 0">
      <div class="accordion-header p-2 d-table w-100">
        <div class="d-table-cell" style="width: 250px;">
          Category
        </div>
        <div
          class="d-table-cell"
          style="width: 450px;"
          v-for="(project, index) in form.project"
          :key="`proj+${index}`"
        >
          {{ project.project_name }}
        </div>
      </div>
      <div
        class="accordion-header p-2 d-table w-100"
        style="font-size: 14px !important;"
      >
        <div class="d-table-cell" style="width: 250px;"></div>
        <div
          class="d-table-cell"
          style="width: 450px;"
          v-for="(project, index) in form.project"
          :key="`proj+${index}`"
        >
          <div class="d-table-cell" style="width: 150px;">Budget</div>
          <div class="d-table-cell" style="width: 150px;">Actual</div>
          <div class="d-table-cell" style="width: 150px;">Balance</div>
        </div>
      </div>

      <div class="accordion" role="tablist">
        <b-card
          no-body
          class="mb-1"
          v-for="(report, index) in reportData"
          :key="`report_item+${index}`"
        >
          <b-card-header
            header-tag="header"
            class="p-1"
            role="tab"
            :class="report.accordion_visible ? null : 'collapsed'"
            :aria-expanded="report.accordion_visible ? 'true' : 'false'"
            :aria-controls="`accordion-${index}`"
            @click="() => accordionClicked(index)"
          >
            <div class="d-table w-100">
              <div class="d-table-cell" style="width: 250px;">
                <p class="h6">{{ report.category_name }}</p>
              </div>
              <div
                class="d-table-cell"
                style="width: 450px;"
                v-for="(project, ind) in form.project"
                :key="`proj+${ind}`"
              >
                <div class="d-table-cell" style="width: 150px;">
                  <p class="h6">{{ report[`budget_${project.project_id}`] }}</p>
                </div>
                <div class="d-table-cell" style="width: 150px;">
                  <p class="h6">{{ report[`actual_${project.project_id}`] }}</p>
                </div>
                <div class="d-table-cell" style="width: 150px;">
                  <p class="h6">
                    {{ report[`balance_${project.project_id}`] }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-header>
          <b-collapse
            :id="`accordion-${index}`"
            v-model="report.accordion_visible"
          >
            <div
              class="p-2 d-table w-100 border-bottom"
              v-for="(child, index) in report.childern"
              :key="`child${index}`"
            >
              <div class="d-table-cell" style="width: 250px;">
                <p>{{ child.category_name }}</p>
              </div>
              <div
                class="d-table-cell"
                style="width: 450px;"
                v-for="(project, ind) in form.project"
                :key="`proj+${ind}`"
              >
                <div class="d-table-cell" style="width: 150px;">
                  {{ child[`budget_${project.project_id}`] }}
                </div>
                <div class="d-table-cell" style="width: 150px;">
                  {{ child[`actual_${project.project_id}`] }}
                </div>
                <div class="d-table-cell" style="width: 150px;">
                  {{ child[`balance_${project.project_id}`] }}
                </div>
              </div>
            </div>
          </b-collapse>
        </b-card>
      </div>
    </div> -->

    <!-- Report Table -->
    <AgGridVue
      v-if="reportData && reportData.length > 0"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="reportData"
      :animateRows="true"
      rowSelection="single"
      @rowSelected="rowClicked"
      :rowClassRules="rowClassRules"
    />
  </vx-card>
</template>

<script>
import ProjectService from "@/services/projectService.js";
import ReportService from "@/services/reportService.js";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import XLSX from "xlsx";
export default {
  components: { AgGridVue },
  data() {
    return {
      form: {
        category: null,
        project: null,
      },
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [],
      reportData: [],
      categoryList: [],
      projectList: [],
      rowClassRules: {
        "child-row": function(params) {
          return params.data.isChild;
        },
      },
    };
  },

  methods: {
    getAllParentCategories() {
      ReportService.getAllParentCategory()
        .then((response) => {
          if (response.data.Status) {
            this.categoryList = response.data.data;
          }
        })
        .catch((error) => console.log("Error => ", error.message));
    },

    getAllProjects() {
      ProjectService.getAllProjects()
        .then((response) => {
          if (response.data.Status) {
            this.projectList = response.data.data;
          }
        })
        .catch((error) => console.log("Error => ", error.message));
    },

    searchData() {
      // if (!this.form.category || !this.form.project) {
      //   return this.$vs.notify({
      //     title: "Missing parameters",
      //     text: "Category or Project is not selected!",
      //     iconPack: "feather",
      //     icon: "check_box",
      //     color: "warning",
      //   });
      // }

      this.$vs.loading();
      ReportService.getMasterSummary(this.form)
        .then((response) => {
          if (!response.data.Status) {
            return this.$vs.notify({
              title: "OOPS!",
              text: response.data.Message || "Data not found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }

          this.columnDefs = [
            {
              headerName: "Category",
              field: "category_name",
              width: 250,
            },
          ];

          response.data.projects
            ? response.data.projects.map((proj) => {
                this.columnDefs.push({
                  headerName: proj.project_name,
                  children: [
                    {
                      headerName: "Budget",
                      field: `budget_${proj.project_id}`,
                      width: 120,
                    },
                    {
                      headerName: "Actual",
                      field: `actual_${proj.project_id}`,
                      width: 120,
                    },
                    {
                      headerName: "Balance",
                      field: `balance_${proj.project_id}`,
                      width: 120,
                    },
                  ],
                });
              })
            : null;

          this.reportData = response.data.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log("Error => ", error.message);
        });
    },

    resetData() {
      this.form = {
        category: null,
        project: null,
      };
      this.reportData = [];
    },

    getMasterSummaryChild(category, rowIndex) {
      let payload = {
        category: category,
        project: this.form.project,
      };
      this.$vs.loading();
      ReportService.getMasterSummaryChilds(payload)
        .then((response) => {
          if (!response.data.Status) {
            this.$vs.loading.close();
            return this.$vs.notify({
              title: "OOPS!",
              text: response.data.Message || "Data not found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }

          let data = response.data.data.map((item) => ({
            ...item,
            isChild: true,
          }));

          this.reportData[rowIndex].rowAlreadyClicked = true;

          if (rowIndex >= 0) {
            this.reportData.splice(rowIndex + 1, 0, ...data);
          } else {
            this.reportData = [...this.reportData, ...data];
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log("Error => ", error.message);
        });
    },

    rowClicked(selected) {
      if (
        (selected.data.hasOwnProperty("isChild") &&
          selected.data.isChild === true) ||
        (selected.data.hasOwnProperty("rowAlreadyClicked") &&
          selected.data.rowAlreadyClicked === true)
      ) {
        return;
      }
      const rowIndex = selected.rowIndex;
      this.getMasterSummaryChild(selected.data.category_name, rowIndex);
    },

    accordionClicked(index) {
      // report.accordion_visible = !report.accordion_visible
      this.reportData[index].accordion_visible = !this.reportData[index]
        .accordion_visible;

      if (!this.reportData[index].childern.length > 0) {
        let payload = {
          category: this.reportData[index].category_name,
          project: this.form.project,
        };
        ReportService.getMasterSummaryChilds(payload)
          .then((response) => {
            if (!response.data.Status) {
              return this.$vs.notify({
                title: "OOPS!",
                text: response.data.Message || "Data not found",
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            }
            this.reportData[index].childern = response.data.data;
          })
          .catch((error) => console.log("Error => ", error.message));
      }

      // console.log("a, b, c, d => ", a, b, c, d);
    },

    getChildern(category) {
      let payload = {
        category: category,
        project: this.form.project,
      };

      ReportService.getMasterSummaryChilds(payload)
        .then((response) => {
          if (!response.data.Status) {
            return this.$vs.notify({
              title: "OOPS!",
              text: response.data.Message || "Data not found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => console.log("Error => ", error.message));
    },

    exportReportExcel() {
      let preparedData = [];
      const data = structuredClone(this.reportData);
      data.forEach((reportItem, i) => {
        let obj = {};
        obj = { ...reportItem };
        if (!reportItem.hasOwnProperty("isChild")) {
          delete obj.childern;
          delete obj.accordion_visible;
          delete obj.isChild;
          delete obj.rowAlreadyClicked;
        } else {
          delete obj.isChild;
        }

        if (obj) {
          preparedData.push(obj);
        }
      });

      let row1 = {};
      let row2 = {};

      this.columnDefs.forEach((head) => {
        if (head.hasOwnProperty("children")) {
          head.children.forEach((child, i) => {
            if (i == 0) {
              row1[child.field] = head.headerName;
            } else {
              row1[child.field] = "";
            }
            row2[child.field] = child.headerName;
          });
        } else {
          row1[head.field] = head.headerName;
          row2[head.field] = "";
        }
      });

      preparedData.unshift(row1, row2);

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(preparedData, {
        skipHeader: true,
      });
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `masterReport.xlsx`);
    },
  },

  beforeMount() {
    this.getAllParentCategories();
    this.getAllProjects();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.child-row {
  background-color: #f8f8f8 !important;
  font-size: 10px !important;
}
.accordion-header {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(
    180deg,
    rgba(var(--vs-primary), 0.8),
    rgba(var(--vs-primary), 0.6)
  );
}

.ag-header-group-cell-with-group {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  background: linear-gradient(
    180deg,
    rgba(var(--vs-primary), 0.8),
    rgba(var(--vs-primary), 0.6)
  );
}
.ag-header-group-cell-no-group {
  background: linear-gradient(
    180deg,
    rgba(var(--vs-primary), 0.8),
    rgba(var(--vs-primary), 0.6)
  );
}
</style>
