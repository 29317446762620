<template>
  <vx-card>
    <div class="flex flex-wrap justify-between items-center form-title">
      <h4>Vendor Summary Report<small>(Unit Economics)</small></h4>
    </div>
    <!-- Filters -->
    <div class="row">
      <div class="col-sm-3 mb-5">
        <h6>Vendor <snap class="text-danger">*</snap></h6>
        <multiselect
          label="vendor_name"
          trackBy="vendor_id"
          v-model="form.vendors"
          :options="vendorList"
          class="w-full"
          :searchable="true"
          placeholder="Select Vendor"
          deselect-label="Remove"
          autocomplete="off"
          :multiple="true"
        />
      </div>
      <div class="col-sm-3 mb-5">
        <h6>Project</h6>
        <multiselect
          label="project_name"
          class="w-full"
          trackBy="project_id"
          v-model="form.projects"
          :options="projectList"
          :searchable="true"
          placeholder="Select Project"
          deselect-label="Remove"
          autocomplete="off"
          multiple
        />
      </div>
      <div class="col-sm-3 mb-5">
        <h6>Category</h6>
        <multiselect
          label="category_name"
          trackBy="category_name"
          class="w-full"
          v-model="form.category"
          :options="categoryList"
          :searchable="true"
          placeholder="Select Category"
          deselect-label="Remove"
          autocomplete="off"
          multiple
          @select="categorySelected"
          @remove="categoryRemoved"
        />
      </div>
      <div class="col-sm-3 mb-5">
        <h6>Sub-Category</h6>
        <multiselect
          label="category_name"
          trackBy="category_id"
          v-model="form.subCategory"
          :options="subCategoryList"
          class="w-full"
          :searchable="true"
          placeholder="Select Vendor"
          deselect-label="Remove"
          autocomplete="off"
          :multiple="true"
        />
      </div>
    </div>
    <!-- <div class="row justify-content-end align-items-center">
      <vs-button
        color="primary"
        type="filled"
        icon="search"
        class="col-12 mb-3 col-sm-2 col-md-1"
        @click="searchData"
      >
        Search
      </vs-button>
      <vs-button
        color="warning"
        type="border"
        class="col-12 col-sm-2 col-md-1 ml-sm-4"
        @click="resetData"
      >
        Reset
      </vs-button>
    </div> -->

    <div class="flex items-center justify-end">
      <div
        class="w-full sm:w-1/4 md:w-1/6 lg:w-1/12 sm:pr-1"
        v-if="reportData.length > 0"
      >
        <vs-button
          color="primary"
          type="filled"
          icon="file_download"
          class="w-full"
          @click="exportExcel"
        >
          Export
        </vs-button>
      </div>
      <div class="w-full sm:w-1/4 md:w-1/6 lg:w-1/12 sm:pr-1">
        <vs-button
          color="primary"
          type="filled"
          icon="search"
          class="w-full"
          @click="searchData"
        >
          Search
        </vs-button>
      </div>
      <div class="w-full sm:w-1/4 md:w-1/6 lg:w-1/12 sm:pr-1">
        <vs-button
          color="warning"
          type="border"
          class="w-full"
          @click="resetData"
        >
          Reset
        </vs-button>
      </div>
    </div>

    <!-- Report Table -->
    <AgGridVue
      v-if="reportData.length > 0"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="reportData"
      :animateRows="true"
    />
  </vx-card>
</template>

<script>
import ContractService from "@/services/ContractService.js";
import ProjectService from "@/services/projectService.js";
import ReportService from "@/services/reportService.js";
import * as XLSX from "xlsx";
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: { AgGridVue },
  data() {
    return {
      moment: moment,
      form: {
        vendors: null,
        projects: null,
        category: null,
        subCategory: null,
      },
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [],
      reportData: [],
      reportProjects: [],
      vendorList: [],
      projectList: [],
      categoryList: [],
      subCategoryList: [],
    };
  },

  methods: {
    getAllVendors() {
      ContractService.getContractVendorNew()
        .then((response) => {
          if (response.data.Status) {
            this.vendorList = response.data.data;
          }
        })
        .catch((error) => console.log("Error => ", error.message));
    },

    getAllProjects() {
      ProjectService.getAllProjects()
        .then((response) => {
          if (response.data.Status) {
            this.projectList = response.data.data;
          }
        })
        .catch((error) => console.log("Error => ", error.message));
    },

    getAllParentCategories() {
      ReportService.getAllParentCategory()
        .then((response) => {
          if (response.data.Status) {
            this.categoryList = response.data.data;
          }
        })
        .catch((error) => console.log("Error => ", error.message));
    },

    getSubCategoryByParentName(name) {
      ReportService.getSubCategoryByParentName({ category: name })
        .then((response) => {
          if (response.data.Status) {
            this.subCategoryList = [
              ...this.subCategoryList,
              ...response.data.data,
            ];
          }
        })
        .catch((error) => console.log("Error => ", error.message));
    },

    categorySelected(selectedOption, id) {
      console.log("selectedOption, id => ", selectedOption, id);
      this.getSubCategoryByParentName(selectedOption.category_name);
    },

    categoryRemoved(removedOption, id) {
      console.log("removedOption, id => ", removedOption, id);
    },

    searchData() {
      if (!this.form.vendors) {
        return this.$vs.notify({
          title: "Missing Vendor",
          text: "Please select Vendor to proceed!",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }
      if (this.form.vendors.length > 5) {
        return this.$vs.notify({
          title: "OOPS",
          text: "Not more than 5 vendors can be selected.",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }
      // if (!this.form.projects) {
      //   return this.$vs.notify({
      //     title: "Missing Project",
      //     text: "Please select Project to proceed!",
      //     iconPack: "feather",
      //     icon: "check_box",
      //     color: "warning",
      //   });
      // }
      this.$vs.loading();
      ReportService.getVendorSummaryUnitEconomics(this.form)
        .then((response) => {
          this.$vs.loading.close();
          if (!response.data.Status) {
            this.reportData = [];
            return this.$vs.notify({
              title: "OOPS!",
              text: response.data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }

          this.columnDefs = [
            {
              headerName: "Vendor",
              field: "vendor_name",
              width: 200,
            },
            {
              headerName: "Category",
              field: "parent_category_name",
              width: 200,
            },
            {
              headerName: "Sub-Category",
              field: "category_name",
              width: 200,
            },
          ];

          this.reportProjects = response.data.data.projects;
          this.reportProjects.map((project) => {
            this.columnDefs.push({
              headerName: project.project_name,
              children: [
                {
                  headerName: "Unit",
                  field: `unit_${project.project_id}`,
                  width: 100,
                },
                {
                  headerName: "Rate",
                  field: `rate_${project.project_id}`,
                  width: 100,
                },
                {
                  headerName: "Amount",
                  field: `amount_${project.project_id}`,
                  width: 120,
                },
              ],
            });
          });

          this.reportData = response.data.data.data;
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log("Error => ", error.message);
        });
    },

    resetData() {
      this.form = {
        vendors: null,
        projects: null,
        category: null,
        subCategory: null,
      };
      this.reportData = [];
    },

    exportExcel() {
      let data = [];
      let header1 = {
        vendor_name: "Vendor",
        parent_category_name: "Category",
        category_name: "Sub Category",
      };
      let header2 = {
        vendor_name: "",
        parent_category_name: "",
        category_name: "",
      };

      this.reportProjects.forEach((project) => {
        header1[`unit_${project.project_id}`] = project.project_name;
        header1[`rate_${project.project_id}`] = "";
        header1[`amount_${project.project_id}`] = "";

        header2[`unit_${project.project_id}`] = "Unit";
        header2[`rate_${project.project_id}`] = "Rate";
        header2[`amount_${project.project_id}`] = "Amount";
      });

      data.push(header1, header2);

      this.reportData.map((item) => {
        console.log("item => ", item);
        let obj = {};
        obj.vendor_name = item.vendor_name;
        obj.parent_category_name = item.parent_category_name;
        obj.category_name = item.category_name;

        this.reportProjects.forEach((project) => {
          obj[`unit_${project.project_id}`] =
            item[`unit_${project.project_id}`];
          obj[`rate_${project.project_id}`] =
            item[`rate_${project.project_id}`];
          obj[`amount_${project.project_id}`] =
            item[`amount_${project.project_id}`];
        });

        data.push(obj);
      });
      var dataOne = XLSX.utils.json_to_sheet(data, { skipHeader: true });
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataOne, "Vendor Unit Economics");
      XLSX.writeFile(
        wb,
        `VendorUnitEconomicsSummaryReport${this.moment().format(
          "DD-MM-YYYY- h:mm:ss"
        )}.xlsx`
      );
    },
  },

  beforeMount() {
    this.getAllVendors();
    this.getAllProjects();
    this.getAllParentCategories();
  },
};
</script>
