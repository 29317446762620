<template>
  <vx-card>
    <div class="flex flex-wrap justify-between items-center form-title">
      <h4>Vendor Summary Report</h4>
    </div>
    <!-- Filters -->
    <div class="row">
      <div class="col-sm-4">
        <h6>Project <snap class="text-danger">*</snap></h6>
        <multiselect
          label="project_name"
          class="w-full"
          value="project_id"
          v-model="form.project"
          :options="projectList"
          :searchable="true"
          open-direction="bottom"
          placeholder="Select Project"
          deselect-label="Remove"
          autocomplete="off"
        />
      </div>
      <div class="col-sm-4">
        <h6>&nbsp;</h6>
        <div class="row">
          <vs-button
            color="primary"
            type="filled"
            icon="search"
            class="col-12 col-sm-5 mr-0 sm:mr-2 mb-2 sm:mb-0"
            @click="searchData"
          >
            Search
          </vs-button>
          <vs-button
            color="warning"
            type="border"
            class="col-12 col-sm-5"
            @click="resetData"
          >
            Reset
          </vs-button>
        </div>
      </div>
    </div>

    <div
      class="flex items-center justify-end mt-5"
      v-if="reportData.length > 0"
    >
      <vs-button
        color="primary"
        type="filled"
        icon="file_download"
        class="w-full sm:w-1/4 md:w-1/6 lg:w-1/12"
        @click="exportExcel"
      >
        Export
      </vs-button>
    </div>

    <!-- Report Table -->
    <AgGridVue
      v-if="reportData.length > 0"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="reportData"
      :animateRows="true"
    />
  </vx-card>
</template>

<script>
import ProjectService from "@/services/projectService.js";
import ReportService from "@/services/reportService.js";
import { AgGridVue } from "ag-grid-vue";
import * as XLSX from "xlsx";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import moment from "moment";
export default {
  components: { AgGridVue },
  data() {
    return {
      moment: moment,
      form: {
        project: null,
      },
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "Vendor",
          field: "vendor_name",
          width: 300,
        },
        {
          headerName: "Expense",
          children: [
            { headerName: "Amount", field: "expense_amount", width: 150 },
            { headerName: "Tax", field: "expense_gst_amount", width: 150 },
            {
              headerName: "Total Amount",
              field: "expense_total_amount",
              width: 150,
            },
          ],
        },
        {
          headerName: "Vendor Advance Request",
          children: [
            { headerName: "Amount", field: "var_amount", width: 150 },
            { headerName: "Tax", field: "var_gst_amount", width: 150 },
            {
              headerName: "Total Amount",
              field: "var_total_amount",
              width: 150,
            },
          ],
        },
        {
          headerName: "Credit Note",
          children: [
            { headerName: "Amount", field: "cn_amount", width: 150 },
            { headerName: "Tax", field: "cn_gst_amount", width: 150 },
            {
              headerName: "Total Amount",
              field: "cn_total_amount",
              width: 150,
            },
          ],
        },
        {
          headerName: "Petty Cash Request",
          children: [
            { headerName: "Amount", field: "pcr_amount", width: 150 },
            { headerName: "Tax", field: "pcr_gst_amount", width: 150 },
            {
              headerName: "Total Amount",
              field: "pcr_total_amount",
              width: 150,
            },
          ],
        },
      ],
      reportData: [],
      projectList: [],
    };
  },

  methods: {
    getAllProjects() {
      ProjectService.getAllProjects()
        .then((response) => {
          if (response.data.Status) {
            this.projectList = response.data.data;
          }
        })
        .catch((error) => console.log("Error => ", error.message));
    },

    searchData() {
      if (!this.form.project) {
        return this.$vs.notify({
          title: "Missing Project",
          text: "Please select Project to proceed!",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }

      this.$vs.loading();
      ReportService.getVendorSummary({
        project_id: this.form.project.project_id,
      })
        .then((response) => {
          this.$vs.loading.close();
          if (!response.data.Status) {
            return this.$vs.notify({
              title: "OOPS!",
              text: response.data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }

          this.reportData = response.data.data;
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log("Error => ", error.message);
        });
    },

    resetData() {
      this.form.project = null;
      this.reportData = [];
    },

    exportExcel() {
      let data = [];
      data.push(
        {
          Vendor: "Vendor",
          "Expense Amount": "Expense",
          "Expense Tax/GST": "",
          "Expense Total": "",
          "VAR Amount": "Vendor Advance Request",
          "VAR Tax/GST": "",
          "VAR Total": "",
          "CN Amount": "Credit Note",
          "CN Tax/GST": "",
          "CN Total": "",
          "PCR Amount": "Petty Cash Request",
          "PCR Tax/GST": "",
          "PCR Total": "",
        },
        {
          Vendor: "",
          "Expense Amount": "Amount",
          "Expense Tax/GST": "Tax/GST",
          "Expense Total": "Total",
          "VAR Amount": "Amount",
          "VAR Tax/GST": "Tax/GST",
          "VAR Total": "Total",
          "CN Amount": "Amount",
          "CN Tax/GST": "Tax/GST",
          "CN Total": "Total",
          "PCR Amount": "Amount",
          "PCR Tax/GST": "Tax/GST",
          "PCR Total": "Total",
        }
      );
      this.reportData.map((item) => {
        let ob = {};
        ob["Vendor"] = item.vendor_name;
        ob["Expense Amount"] = item.expense_amount;
        ob["Expense Tax/GST"] = item.expense_gst_amount;
        ob["Expense Total"] = item.expense_total_amount;
        ob["VAR Amount"] = item.var_amount;
        ob["VAR Tax/GST"] = item.var_gst_amount;
        ob["VAR Total"] = item.var_total_amount;
        ob["CN Amount"] = item.cn_amount;
        ob["CN Tax/GST"] = item.cn_gst_amount;
        ob["CN Total"] = item.cn_total_amount;
        ob["PCR Amount"] = item.pcr_amount;
        ob["PCR Tax/GST"] = item.pcr_gst_amount;
        ob["PCR Total"] = item.pcr_total_amount;
        data.push(ob);
      });

      var dataOne = XLSX.utils.json_to_sheet(data, { skipHeader: true });
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataOne, "Vendor Summary");
      XLSX.writeFile(
        wb,
        `VendorSummaryReport${this.moment().format("DD-MM-YYYY- h:mm:ss")}.xlsx`
      );
    },
  },

  beforeMount() {
    this.getAllProjects();
  },
};
</script>
